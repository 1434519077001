

export function calculateContainerSize(wSize, aspectRatio) {

    var currentWidth = wSize.width;//div.width();
    var currentHeight = wSize.height;//div.height();
    var nWidth = currentWidth;
    var nHeight = currentWidth * (1/aspectRatio);
    //check height
    if (currentHeight<nHeight) {
      nWidth = currentHeight * aspectRatio;
      nHeight = currentHeight;
    }

    var contSize = {
      width: nWidth,
      height: nHeight,
      leftMargin: (currentWidth-nWidth)/2,
      topMargin: (currentHeight-nHeight)/2,
      scaleRatio: nWidth / 1920
    };

    return contSize;
}

export function getAppType() {
    let search = window.location.pathname;
    if (search.indexOf("/avp/touch")!==-1) {
      return 0;
    }
    else 
    if (search.indexOf("/avp/olo")!==-1) {
      return 1;
    }    
    else 
    if (search.indexOf("/avp/qrcode")!==-1) {
      return 2;
    }      
    else 
    if (search.indexOf("/avp/interaction")!==-1) {
      return 3;
    }  
    else 
    if (search.indexOf("/avp/checkpin")!==-1) {
      return 4;
    }  

    return -1;
}

export function getValueByQueryString(paramName) {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let sessionId = params.get(paramName);
    return sessionId;
}