import React from 'react'
import { useState } from 'react';
import InteractionButton from './InteractionButton';

export default function Interaction({size, nodeData, clickCallback}) {  

    const renderButtons = () => {
      let td = [];
      if (nodeData!=null) {
        if (nodeData.avpArcos!=null && nodeData.avpArcos.length>0) {
          for (let i = 0; i<nodeData.avpArcos.length; i++) {
            let arc = nodeData.avpArcos[i];
            td.push(<InteractionButton key={"b"+i} buttonId={"b"+i} arc={arc} clickCallback={clickCallback} /> );
          }
        }
      }
      return td;
    };

    return (
      <div id="avp-interaction"  >
        {renderButtons()}

      </div>
    ); 
  
  }   

