import React from 'react'
import { useState } from 'react';
import Interaction from './Interaction';

export default function InteractionScreen({size, node, clickCallback, restartCallback}) {  

  const containerStyle = {
    width: "100%",
    height: "100%",
    left: 0,
    top: 0
  };

  function handleRestart() {
    console.log("handleRestart");
    if (restartCallback!=null) {
      restartCallback();
    }
  }   

  return (
    <div id="avp-interaction-screen" style={containerStyle} >
      <div id="avp-interaction-screen-body">
        <img id="avp-interaction-screen-body-bkg" src="/images/bkg_avatar_sx.png" />
        <div id="avp-interaction-main">
            <Interaction size={size} node={node} clickCallback={clickCallback} />
        </div>
        <div id="avp-interaction-companylogo">
          <img src="/images/company_logo.png" />
        </div>
        <div id="avp-interaction-restart" onClick={()=>handleRestart()} >
          <img src="/images/restart_button.png" />
        </div>          
      </div>
    </div>
  ); 

} 