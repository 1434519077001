import React from 'react'
import { useState, useRef } from 'react';
import ReactPlayer from 'react-player'

export default function PlaylistItem({item, loop, size, finishCallback}) {  

    const timeOut = useRef(null);

    function handleTempoScaduto() {
        console.log("handleTempoScaduto");
        if (finishCallback!=null) {
            finishCallback(item);
        }
    }

    if (item!=null) {



        if (item.durataVisualizzazione!=0) {
            if (timeOut!=null) {
                clearTimeout(timeOut.current);
                timeOut.current = setTimeout(() => {
                    handleTempoScaduto();
                }, item.durataVisualizzazione*1000);
            }
        }

        if (item.tipoContenuto==2) {

            return (
                <div className="avp-playlist-item" id={item.codiceFile}   >
                    <img 
                        src={item.pathFile} 
                    />
                </div>
            );
        }
        else
        if (item.tipoContenuto==1 || item.tipoContenuto==5) {
            return (
                <div className="avp-playlist-item"   >
                   <ReactPlayer
                      url={item.pathFile}
                      playing={true}
                      width="100%"
                      height="100%"
                      loop={loop}
                      onEnded={()=>handleTempoScaduto()}
                    />                   
                </div>
            );
        }
    }

    return (
        <div className="avp-playlist-item"  ></div>
    );
  
  }   
