// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#avp-avatar {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);  
  left: 0;
  z-index: 2;
  overflow: hidden;
  -ms-overflow-style: none;
}

#avp-avatar-container {
  position: relative;
  width: 100%;
  height: auto;
  z-index: 3;
  overflow: hidden;
  -ms-overflow-style: none;
}

#avp-avatar-container > img {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#avp-avatar-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 4;
}

#avp-avatar-mask > img {
  width: 100%;
  height: 100%;
}

#avp-avatar-container-iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    /* height: auto; */
    z-index: 3;
}

#avp-avatar-container-iframe iframe {
  width: 100%;
  height: 100%;
  border: 0;
}
`, "",{"version":3,"sources":["webpack://./src/avatar.css"],"names":[],"mappings":";AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,6BAA6B;EAC7B,OAAO;EACP,UAAU;EACV,gBAAgB;EAChB,wBAAwB;AAC1B;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,UAAU;EACV,gBAAgB;EAChB,wBAAwB;AAC1B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,MAAM;EACN,OAAO;EACP,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,UAAU;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,SAAS;AACX","sourcesContent":["\n#avp-avatar {\n  position: absolute;\n  top: 50%;\n  transform: translate(0, -50%);  \n  left: 0;\n  z-index: 2;\n  overflow: hidden;\n  -ms-overflow-style: none;\n}\n\n#avp-avatar-container {\n  position: relative;\n  width: 100%;\n  height: auto;\n  z-index: 3;\n  overflow: hidden;\n  -ms-overflow-style: none;\n}\n\n#avp-avatar-container > img {\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n}\n\n#avp-avatar-mask {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  z-index: 4;\n}\n\n#avp-avatar-mask > img {\n  width: 100%;\n  height: 100%;\n}\n\n#avp-avatar-container-iframe {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    /* height: auto; */\n    z-index: 3;\n}\n\n#avp-avatar-container-iframe iframe {\n  width: 100%;\n  height: 100%;\n  border: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
