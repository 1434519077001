
export async function apiAuthenticate(client, authData, callback) {
    try {
        let response = await client.post('/User/Authenticate', authData);
        if (callback)
          callback(response);
    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }
};  

export async function apiClientGetSessionStatusById(client, token, idutente, idavp, callback) {
    try {
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/Client/ClientGetSessionStatusById/${idutente}/${idavp}`, config);
        if (callback)
          callback(response);
    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }
};  


export async function apiStartAVP(client, token, idutente, idavp, callback) {
    try {
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/Client/ClientStartAVP/${idutente}/${idavp}`, config);
        if (callback)
          callback(response);
    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};  

export async function apiStartSession(client, token, idutente, idavp, idlingua, qrToken, callback) {
    try {
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        let response = null;
        if (qrToken!=null && qrToken.length>0) {
            response = await client.get(`/Client/ClientStartSession/${idutente}/${idavp}/${idlingua}/${qrToken}`, config);
        }
        else {
            response = await client.get(`/Client/ClientStartSession/${idutente}/${idavp}/${idlingua}`, config);
        }

        if (callback)
          callback(response);
    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
}; 

export async function apiGoToNextNode(client, token, codSessione, idarco, callback) {
    try {
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/Client/ClientGoToNextNode/${codSessione}/${idarco}`, config);
        if (callback)
          callback(response);
    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};

export async function apiTerminateSession(client, token, idutente, idavp, callback) {
    try {
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };
        
        let response = await client.get(`/Client/ClientTerminateSession/${idutente}/${idavp}`, config);
        if (callback)
          callback(response);
    }
    catch(error) {
        console.error(error);
        if (callback)
            callback(null);
    }    
};