import React from 'react'
import { useState, useEffect } from 'react';
import { calculateContainerSize } from "./manager/util";
import Avatar from './Avatar';
import Playlist from './Playlist';

export default function OloScreen({size, node, nodeId}) {  

  console.log("OloScreen");

  const [showAvatar, setShowAvatar] = useState(true);
  const [idNode, setIdNode] = useState(nodeId);

  console.log("nodeId="+nodeId);
  useEffect(() => {
    console.log("nodeId="+nodeId);
    setShowAvatar(true)
  }, [nodeId]);

  const cSize = calculateContainerSize(size, 1);

  const minSize = (size.width<size.height) ? size.width : size.height;

  const pSize  = {
    width: minSize,
    height: minSize
  };

  const containerStyle = {
    width: cSize.width,
    height: cSize.height,
    left: cSize.leftMargin,
    top: cSize.topMargin
  };

  function handleAvatarShown() {
    console.log("handleAvatarShown");
    setShowAvatar(false);
  }  
  
  if (node!=null) {
    console.log("OloScreen > node ok");
    console.log("showAvatar="+showAvatar);
    if (showAvatar) {
      if (node.avpAvatars!=null && node.avpAvatars.length>0) {
        let avatar = node.avpAvatars[0];
        let loop = (node.avpPlaylistFiles==null || node.avpPlaylistFiles.length==0);
        let handleFinish = loop ? null : handleAvatarShown;
        return (
          <div id="avp-olo-screen" style={containerStyle} >
            <div id="avp-olo-screen-body">
              <div id="avp-olo-main">
                <Avatar avatar={avatar} finishCallback={handleFinish} loop={loop} size={pSize} />
              </div>
            </div>
          </div>
        ); 
      
      }
      else {
        console.log("no avatars");
      }
    }
    else {
      if (node.avpPlaylistFiles!=null && node.avpPlaylistFiles.length>0) {
        return (
          <div id="avp-olo-screen" style={containerStyle} >
            <div id="avp-olo-screen-body">
              <div id="avp-olo-main">
                <Playlist items={node.avpPlaylistFiles} size={pSize} />
              </div>
            </div>
          </div>
        ); 
      }
    }

  }
  else {
    console.error("node is null");
  }


  return (
    <div id="avp-olo-screen" style={containerStyle} >
      <div id="avp-olo-screen-body">
        <div id="avp-olo-main">

        </div>
      </div>
    </div>
  ); 

} 