import React from 'react'
import { useState } from 'react';
import PlaylistItem from './PlaylistItem';

export default function Playlist({items, size}) {  

    let sizeW = size.width;
    let sizeH = size.height;
    let margH = 0;
    let margV = 0;
 
    if (size.width!=size.height) {
      if (sizeW>sizeH) {
        margH = (sizeW-sizeH)/2;
        sizeW = sizeH; 
      }
      else {
        margV = (sizeH-sizeW)/2;
        sizeH = sizeW;
      }
    }
    const pSize  = {
        width: sizeH,
        height: sizeW
    };  
    console.log("PLAYLIST SIZE", pSize); 

    let currentItem = null;
    let isLast = false;
    const [currentIndex, setCurrentIndex] = useState(0);
    const [count, setCount] = useState(0);

    function nextItem() {
        if (items!=null) {
            let newIndex = 0;
            if (currentIndex<(items.length-1)) {
                newIndex = currentIndex+1;
            }
            console.log("set new index="+newIndex);
            setCurrentIndex( newIndex);
            setCount(count+1);
        }
    }

    function finishCallback(item) {
        console.log("finishCallback");
        nextItem();
    }

    if (items!=null && items.length>0 && currentIndex>=0) {
        console.log("Playlist currentIndex="+currentIndex);
        currentItem = items[currentIndex];
        console.log("Playlist currentItem:", currentItem);
        isLast = currentIndex==(items.length-1);
    }

    return (
        <div className="avp-playlist"  >
            <PlaylistItem item={currentItem} loop={isLast} finishCallback={finishCallback} size={pSize} />
        </div>
    );
  
  }   
