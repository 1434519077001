import React from 'react'
import { useState, useRef, useEffect } from 'react';
import QRCodeStyling from "qr-code-styling";

export default function QRCode({size, node, qrCode}) {  

  const [currentQRCode, setCurrentQRCode] = useState(null);

  const qrCodeDivRef = useRef(null);

  useEffect(() => {
    if (currentQRCode==null) {
      console.log("processing");
      const qrCodeJson = JSON.parse(qrCode.json);
      const qrCodeStyling = new QRCodeStyling(qrCodeJson);
      qrCodeDivRef.current.innerHTML = "";
      qrCodeStyling.append(qrCodeDivRef.current);

      setCurrentQRCode(qrCode);
    }

  }, [qrCode]);

    if (qrCode!=null) {
      let textAlign = "left";
      if (qrCode.align==2) textAlign="center";
      if (qrCode.align==3) textAlign="right";
      let textClass = qrCode.styleClass!=null ? qrCode.styleClass : ""; 
      return (
        <div id="avp-qrcode-object"  >
          {
            qrCode.preset==1 ?
            (
              <div id="avp-qrcode-container"  >
                <div id="avp-qrcode-text" style={{"textAlign": textAlign}} className={textClass} >{qrCode!=null ? qrCode.text : ""}</div>
                <div id="avp-qrcode-content" ref={qrCodeDivRef} ></div>     
              </div>          
            )
            :
            (
              <div id="avp-qrcode-container"  >
                <div id="avp-qrcode-content" ref={qrCodeDivRef} ></div>     
                <div id="avp-qrcode-text" style={{"textAlign": textAlign}} className={textClass} >{qrCode!=null ? qrCode.text : ""}</div>
              </div>    
            )
          }
        </div>
      ); 
    }
    else {
      return(<div id="avp-qrcode-object"  ></div>)
    }

  
  }      
