import React from 'react'
import { useState } from 'react';
import InteractionButton from './InteractionButton';

export default function Interaction({size, node, clickCallback}) {  

    const renderButtons = () => {
      let td = [];
      if (node!=null) {
        if (node.avpArcos!=null && node.avpArcos.length>0) {
          for (let i = 0; i<node.avpArcos.length; i++) {
            let arc = node.avpArcos[i];
            td.push(<InteractionButton key={"b"+i} buttonId={"b"+i} arc={arc} clickCallback={clickCallback} /> );
          }
        }
      }
      return td;
    };

    return (
      <div id="avp-interaction"  >
        {renderButtons()}

      </div>
    ); 
  
  }   

