import React from 'react'
import { useState } from 'react';
import ReactPlayer from 'react-player'
import { JitsiMeeting } from '@jitsi/react-sdk';

export default function Avatar({avatar, loop, finishCallback, size}) {  

    const playerRef = React.useRef();
    const iframeRef = React.useRef();
    
    let sizeW = size.width;
    let sizeH = size.height;
    let margH = 0;
    let margV = 0;
    if (size.width!=size.height) {
      if (sizeW>sizeH) {
        margH = (sizeW-sizeH)/2;
        sizeW = sizeH; 
      }
      else {
        margV = (sizeH-sizeW)/2;
        sizeH = sizeW;
      }
    }
    const pSize  = {
      width: sizeH,
      height: sizeW
    };  
    console.log("AVATAR SIZE", pSize);

    const iFrameStyle = {
      position: 'relative',
      width: sizeW,
      height: sizeH,
      left: 0,
      top: 0,
      border: 0,
    };

    function handleTempoScaduto() {
        if (finishCallback!=null) {
            finishCallback(avatar);
        }
        else {
          console.log("no finishCallback");
        }
    }

    function handleIFrameLoad() {
      console.log("handleIFrameLoad", iframeRef);
      const iframeDoc = iframeRef.current?.contentWindow;
      console.log("iframeDoc", iframeDoc);
      if (iframeDoc!=null) {
        /*
        let cssStyle = iframeDoc.document.createElement("style");
        cssStyle.innerHTML = "head { display:none !important;} footer { display: none !important;}";
        iframeDoc.document.head.appendChild(cssStyle);  
        */
      }
      else {
        console.error("no iframe doc");
      }
   
      
    }

    function handleIFrame3Load() {
      console.log("handleIFrame3Load", iframeRef);
      const iframeDoc = iframeRef.current?.contentWindow;
      console.log(iframeDoc);
      if (iframeDoc!=null) {

        let script = iframeDoc.document.createElement("script");
        script.innerHTML = "setTimeout( function() { alert(\"ciao\"); }, 2000 );";
        iframeDoc.document.head.appendChild(script);  
      }
      else {
        console.error("no iframe doc");
      }
   
      
    }


    

    if (avatar!=null) {
        if (avatar.tipoContenuto==0) {
            return (
                <div id="avp-avatar"  >
                  <div id="avp-avatar-container"  >
                    <img src={avatar.pathFile}  />
                  </div>
                  <div id="avp-avatar-mask"  >
                    <img src="/images/campo_sfocatura.png" />
                  </div>
                </div>              
            );
        }
        else
        if (avatar.tipoContenuto==5) {
            return (
                <div id="avp-avatar"  >
                  <div id="avp-avatar-container"  >
                  <ReactPlayer
                      ref={playerRef}
                      url={avatar.pathFile}
                      playing={true}
                      width={pSize.width}
                      height={pSize.height}
                      loop={loop}
                    
                      onEnded={()=>handleTempoScaduto()}
                    />

                  </div>
                  <div id="avp-avatar-mask"  >
                    <img src="/images/campo_sfocatura.png" />
                  </div>
                </div>                
            );
        }
        else
        if (avatar.tipoContenuto==3) {
          return (
              <div id="avp-avatar"  >
                <JitsiMeeting
                    domain = {avatar.pathFile}
                    roomName = "test"
                    configOverwrite = {{
                        startWithAudioMuted: true,
                        disableModeratorIndicator: true,
                        startScreenSharing: true,
                        enableEmailInStats: false,
                        prejoinConfig: {
                          enabled: false
                        }
                    }}
                    interfaceConfigOverwrite = {{
                        DISABLE_JOIN_LEAVE_NOTIFICATIONS: true
                    }}
                    userInfo = {{
                        displayName: 'DEMO'
                    }}
                    onApiReady = { (externalApi) => {
                        // here you can attach custom event listeners to the Jitsi Meet External API
                        // you can also store it locally to execute commands
                    } }
                    getIFrameRef = { (iframeRef) => { iframeRef.style.height = sizeH+'px'; iframeRef.style.width = sizeW+'px'; } }
                />
                <div id="avp-avatar-mask"  >
                  <img src="/images/campo_sfocatura.png" />
                </div>                
              </div>              
          );
        }        
        else
        if (avatar.tipoContenuto==4) {
          return (
              <div id="avp-avatar"  >
                  <iframe style={iFrameStyle}  src={avatar.pathFile} onLoad={()=>handleIFrameLoad()} ref={iframeRef} />
              </div>              
          );
      }        
    }

    return (
        <div class="avp-avatar"  ></div>
    );


  
  }      


