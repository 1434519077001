import React from 'react'
import { useState, useEffect } from 'react';
import Interaction from './Interaction';
import Avatar from './Avatar';
import { calculateContainerSize } from "./manager/util";
import Playlist from './Playlist';
import QRCode from './QRCode';

export default function TouchScreen({size, node, nodeId, clickCallback, restartCallback, qrCode}) {  

  const [showAvatar, setShowAvatar] = useState(true);


  useEffect(() => {
    console.log("nodeId="+nodeId);
    //console.log("qrCode=",qrCode);
    
    setShowAvatar(true)
  }, [nodeId]);

  const cSize = calculateContainerSize(size, 16/9);
  const pSize  = {
    width: (cSize.width / 2),
    height: cSize.height
  };

  const containerStyle = {
    width: cSize.width,
    height: cSize.height,
    left: cSize.leftMargin,
    top: cSize.topMargin
  };

  function handleAvatarShown() {
    console.log("handleAvatarShown");
    setShowAvatar(false);
  }  

  function handleRestart() {
    console.log("handleRestart");
    if (restartCallback!=null) {
      restartCallback();
    }
  }  

  if (node!=null) {
    console.log("TouchScreen > node ok");
    if (node.avpAvatars!=null && node.avpAvatars.length>0) {
      let avatar = node.avpAvatars[0];
      let loop = (node.avpPlaylistFiles==null || node.avpPlaylistFiles.length==0);
      let handleFinish = loop ? null : handleAvatarShown;
      return (
        <div id="avp-touch-screen" style={containerStyle} >
          <div id="avp-touch-screen-body">
            <img id="avp-touch-screen-body-bkg" src="/images/bkg_avatar_sx.png" />
            <div id="avp-touch-main">
              <div id="avp-touch-main-left">
                {showAvatar ? (
                  <Avatar avatar={avatar} finishCallback={handleFinish} loop={loop} size={pSize} />
                ) : (
                  <Playlist items={node.avpPlaylistFiles} size={pSize} />
                )}  
              </div>
              <div id="avp-touch-main-right">
                {
                  qrCode!=null ? 
                  (<QRCode size={size} node={node} qrCode={qrCode} />)
                  :
                  (<Interaction size={size} node={node} clickCallback={clickCallback} />)
                }
              </div>          
            </div>
            <div id="avp-touch-companylogo">
              <img src="/images/company_logo.png" />
            </div>
            <div id="avp-touch-restart" onClick={()=>handleRestart()} >
              <img src="/images/restart_button.png" />
            </div>            
          </div>
        </div>
      ); 
    
    }
    

  }
  else {
    console.error("node is null");
  }  

  return (
    <div id="avp-touch-screen" style={containerStyle} >
      <div id="avp-touch-screen-body">
        <img id="avp-touch-screen-body-bkg" src="/images/bkg_avatar_sx.png" />
        <div id="avp-touch-main">
        </div>
        <div id="avp-touch-companylogo">
          <img src="/images/company_logo.png" />
        </div>
        <div id="avp-touch-restart" onClick={()=>handleRestart()} >
          <img src="/images/restart_button.png" />
        </div>            
      </div>
    </div>
  ); 

}  
 