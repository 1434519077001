import React from 'react'
import { useState, useEffect } from 'react';
import QRCode from './QRCode';
import { calculateContainerSize } from "./manager/util";

export default function QRCodeScreen({size, node, nodeId, qrCode}) {  

  useEffect(() => {
    console.log("nodeId="+nodeId);
    //console.log("qrCode=",qrCode);
    
  }, [nodeId]);

  const cSize = calculateContainerSize(size, 16/9);

  const containerStyle = {
    width: cSize.width,
    height: cSize.height,
    left: cSize.leftMargin,
    top: cSize.topMargin
  };


  return (
    <div id="avp-qrcode-screen" style={containerStyle} >
      <div id="avp-qrcode-screen-body">
        <img id="avp-qrcode-screen-body-bkg" src="/images/bkg_avatar_sx.png" />
        <div id="avp-qrcode-main">
        {
            qrCode!=null ? 
            (<QRCode size={size} qrCode={qrCode} />)
            :
            (<br />)
        }    
        </div>
        <div id="avp-qrcode-pip">
          
        </div>  
        <div id="avp-qrcode-companylogo">
          <img src="/images/company_logo.png" />
        </div>
      </div>
    </div>
  ); 

} 