// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `


#avp-qrcode-main {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;  
}

#avp-qrcode-pip {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;  
  display: none;
}

#avp-qrcode-companylogo {
  position: absolute;
  top: 2%;
  left: 1.5%;
  width: 12%;
  height: auto;
  z-index: 50;
}

#avp-qrcode-companylogo > img {
  width: 100%;
  height: 100%;
}

#avp-qrcode-main-left {
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
}

#avp-qrcode-main-right {
  position: absolute;
  width: 50%;
  height: 100%;
  left: 50%;
  top: 0;
  overflow: hidden;
}

#avp-qrcode-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    overflow: hidden;
    -ms-overflow-style: none;
}

#avp-qrcode-text {
  padding: 30px;
  font-size: 24px;
  font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/qrcodescreen.css"],"names":[],"mappings":";;;AAGA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,UAAU;EACV,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,UAAU;EACV,WAAW;EACX,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,OAAO;EACP,UAAU;EACV,UAAU;EACV,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,OAAO;EACP,MAAM;EACN,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,SAAS;EACT,MAAM;EACN,gBAAgB;AAClB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,UAAU;IACV,gBAAgB;IAChB,wBAAwB;AAC5B;;AAEA;EACE,aAAa;EACb,eAAe;EACf,gBAAgB;AAClB","sourcesContent":["\n\n\n#avp-qrcode-main {\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 2;\n  width: 100%;\n  height: 100%;  \n}\n\n#avp-qrcode-pip {\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 2;\n  width: 100%;\n  height: 100%;  \n  display: none;\n}\n\n#avp-qrcode-companylogo {\n  position: absolute;\n  top: 2%;\n  left: 1.5%;\n  width: 12%;\n  height: auto;\n  z-index: 50;\n}\n\n#avp-qrcode-companylogo > img {\n  width: 100%;\n  height: 100%;\n}\n\n#avp-qrcode-main-left {\n  position: absolute;\n  width: 50%;\n  height: 100%;\n  left: 0;\n  top: 0;\n  overflow: hidden;\n}\n\n#avp-qrcode-main-right {\n  position: absolute;\n  width: 50%;\n  height: 100%;\n  left: 50%;\n  top: 0;\n  overflow: hidden;\n}\n\n#avp-qrcode-container {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    z-index: 2;\n    overflow: hidden;\n    -ms-overflow-style: none;\n}\n\n#avp-qrcode-text {\n  padding: 30px;\n  font-size: 24px;\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
