// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `


#avp-touch-main {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;  
}

#avp-touch-pip {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;  
  display: none;
}

#avp-touch-companylogo {
  position: absolute;
  top: 2%;
  left: 1.5%;
  width: 12%;
  height: auto;
  z-index: 50;
}

#avp-touch-companylogo > img {
  width: 100%;
  height: 100%;
}

#avp-touch-restart {
  position: absolute;
  bottom: 2%;
  left: 1.5%;
  width: 7%;
  height: auto;
  z-index: 50;
  cursor: pointer;
}

#avp-touch-restart > img {
  width: 100%;
  height: 100%;
}

#avp-touch-main-left {
  position: absolute;
  width: 50%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
}

#avp-touch-main-right {
  position: absolute;
  width: 50%;
  height: 100%;
  left: 50%;
  top: 0;
  overflow: hidden;
}

#avp-touch-main-right #avp-interaction {
  margin-top: 10%;
  margin-bottom: 10%;
  margin-left: 5%;
  margin-right: 10%;  
  height: 80%;
}

#avp-touch-companylogo {
  width: 18%;
}`, "",{"version":3,"sources":["webpack://./src/touchscreen.css"],"names":[],"mappings":";;;AAGA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,UAAU;EACV,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,UAAU;EACV,WAAW;EACX,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,OAAO;EACP,UAAU;EACV,UAAU;EACV,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,UAAU;EACV,SAAS;EACT,YAAY;EACZ,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,OAAO;EACP,MAAM;EACN,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,SAAS;EACT,MAAM;EACN,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,UAAU;AACZ","sourcesContent":["\n\n\n#avp-touch-main {\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 2;\n  width: 100%;\n  height: 100%;  \n}\n\n#avp-touch-pip {\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 2;\n  width: 100%;\n  height: 100%;  \n  display: none;\n}\n\n#avp-touch-companylogo {\n  position: absolute;\n  top: 2%;\n  left: 1.5%;\n  width: 12%;\n  height: auto;\n  z-index: 50;\n}\n\n#avp-touch-companylogo > img {\n  width: 100%;\n  height: 100%;\n}\n\n#avp-touch-restart {\n  position: absolute;\n  bottom: 2%;\n  left: 1.5%;\n  width: 7%;\n  height: auto;\n  z-index: 50;\n  cursor: pointer;\n}\n\n#avp-touch-restart > img {\n  width: 100%;\n  height: 100%;\n}\n\n#avp-touch-main-left {\n  position: absolute;\n  width: 50%;\n  height: 100%;\n  left: 0;\n  top: 0;\n  overflow: hidden;\n}\n\n#avp-touch-main-right {\n  position: absolute;\n  width: 50%;\n  height: 100%;\n  left: 50%;\n  top: 0;\n  overflow: hidden;\n}\n\n#avp-touch-main-right #avp-interaction {\n  margin-top: 10%;\n  margin-bottom: 10%;\n  margin-left: 5%;\n  margin-right: 10%;  \n  height: 80%;\n}\n\n#avp-touch-companylogo {\n  width: 18%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
