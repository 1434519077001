import React from 'react'
import { useState, useEffect } from 'react';
import { calculateContainerSize } from "./manager/util";
import '../checkpinscreen.css';

export default function CheckPinScreen({size, nodeId, pinCallback}) {  

  useEffect(() => {
    console.log("nodeId="+nodeId);
    //console.log("qrCode=",qrCode);
    
  }, [nodeId]);

  const formPinInput = React.useRef();

  const cSize = calculateContainerSize(size, 16/9);

  const containerStyle = {
    width: cSize.width,
    height: cSize.height,
    left: cSize.leftMargin,
    top: cSize.topMargin
  };

    function handleSubmit() {
        const pin = formPinInput.current.value;
        pinCallback(pin);
    }  


  return (
    <div id="avp-checkpin-screen" className="clearfix group" >
      <div id="avp-checkpin-screen-body" className="clearfix group">
        <img id="avp-checkpin-screen-body-bkg" src="/images/bkg_avatar_sx.png" />
        <div id="avp-checkpin-main" className="clearfix group">
            <div id="avp-checkpin-content" className="clearfix group">
                <label>PIN</label>
                <br/><br/>
                <input ref={formPinInput} type="text"></input>
                <br/><br/>
                <button onClick={handleSubmit}>OK</button>
                
            </div>

        </div>
        <div id="avp-checkpin-companylogo">
          <img src="/images/company_logo.png" />
        </div>
      </div>
    </div>
  ); 

} 