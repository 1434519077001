import React from 'react'
import { useState } from 'react';

export default function InteractionButton({buttonId, arc, clickCallback}) {  

    let bkgImage = "/images/btn_question.png";
    let showAsDisabled = (arc.idNodoEnd==arc.idNodoStart); 
    if (showAsDisabled) {
      bkgImage = "/images/btn_question_selected.png";
    }

    return (
      <div className="avp-interaction-button" id={buttonId} onClick={()=>clickCallback(arc)} >
        <img className="avp-interaction-button-bkg" src={bkgImage} />
        <div className="avp-interaction-button-icon" >
            <img src="/images/icon_teletabber.png" />
        </div>
        <div className="avp-interaction-button-text">
            <span className="avp-interaction-button-text-box">{arc.testo}</span>
        </div>
      </div>
    ); 
  
  }   
