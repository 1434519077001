import React from 'react'
import { useState, useEffect } from 'react';
import { calculateContainerSize } from "./manager/util";
import NodeContent from './NodeContent';

export default function OloScreen({size, node, nodeId}) {  


  useEffect(() => {
    console.log("OloScreen nodeId="+nodeId);

  }, [nodeId]);

  const cSize = calculateContainerSize(size, 1);

  const minSize = (size.width<size.height) ? size.width : size.height;

  const pSize  = {
    width: minSize,
    height: minSize
  };

  const containerStyle = {
    width: "100%",
    height: "100%",
    left: 0,
    top: 0
  };


  return (
    <div id="avp-olo-screen" style={containerStyle} >
      <div id="avp-olo-screen-body">
        <div id="avp-olo-main">
          <NodeContent nodeData={node} nodeId={nodeId} size={pSize} />
        </div>
      </div>
    </div>
  ); 

} 