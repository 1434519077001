
import { getAppType, getValueByQueryString } from "./util";

export function getAuthData() {
    const authData = {
        "idutente": getIDUtente(),
        "idRuolo": 0,
        "idGruppo": 0,
        "nome": "string",
        "cognome": "string",
        "emailUtente": "romolo.rubeo@gmail.com",
        "telefono": "string",
        "userName": "admin",
        "passwordHash": "AVPadmin",
        "sysUser": "string",
        "sysDate": "2024-05-11T08:35:46.322Z",
        "sysAttivo": true
      };
    return authData;  
}

export function getIDUtente() {
    let value = getValueByQueryString("idUtente");
    return value;  
}

export function getAvp() {
    let value = getValueByQueryString("idAvp");
    return value;  
}

export function getLingua() {
    return 1;  
}